<template>
	<div class="wrap">
		<router-view name="header"></router-view>
		<div class="lnb-exist">
			<router-view name="side"></router-view>
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
// import Fbx from './Fbx';

export default {
	name: 'App',
	components: {},
	computed: {},
	data() {
		return {};
	},
	created() {
		// this.controls = new OrbitControls(this.camera, this.$refs.renderer);
		// this.loader = new GLTFLoader();
	},
	mounted() {},
	methods: {},
};
</script>
