import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import headerComp from '../components/HeaderComponent';
import sideComp from '../components/SideComponent';

const rUser = {
	path: '/admin',
	name: 'User',
	redirect: '/admin/item',
	meta: { user: true },
	components: {
		default: () => import('../views/User/Main.vue'),
		header: headerComp,
		side: sideComp,
	},
	children: [
		{
			path: 'runApp',
			component: () => import('../views/Invest/RunApp.vue'),
			meta: { master: true },
		},
		{
			path: 'log',
			component: () => import('../views/Invest/LogList.vue'),
			meta: { master: true },
		},
		{
			path: 'item',
			component: () => import('../views/Invest/ItemList.vue'),
			meta: { master: true },
		},
		{
			path: 'setting',
			component: () => import('../views/Invest/Setting.vue'),
			meta: { master: true },
		},
		{
			path: 'review',
			component: () => import('../views/Invest/Review.vue'),
			meta: { master: true },
		},
	],
};

const routes = [
	{
		path: '/test',
		name: 'test',
		components: {
			default: () => import('../views/Test.vue'),
			header: headerComp,
			side: sideComp,
		},
		header: headerComp,
		side: sideComp,
	},
	{
		path: '/LoginView',
		name: 'LoginView',
		component: () => import('../views/LoginView.vue'),
		meta: { unauthorized: true },
	},
	{
		path: '/',
		name: 'main',
		components: {
			default: () => import('../views/Main.vue'),
			header: headerComp,
			side: sideComp,
		},
	},
	rUser, //회원 관리
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const storeToken = store.getters.getToken;
	if (to.matched.some(record => record.meta.unauthorized)) {
		return next();
	} else if (to.matched.some(record => record.meta.master) && storeToken) {
		if (store.getters.getUserInfo.grade == 0) {
			return next();
		} else {
			return next('/');
		}
	} else if (storeToken) {
		return next();
	}

	// alert('로그인 해주세요');
	return next('/LoginView');
});

export default router;
