<template>
	<div class="lnb-wrap">
		<!-- <div class="lnb-tit"><b-icon-arrow-right-circle-fill />사이트 바로가기</div> -->
		<ul class="lnb-list">
			<!-- <li class="lnb-list--item" v-if="$store.getters.getUserInfo.dashboard == 'Y'">
				<router-link
					type="button"
					class="btn tit only"
					to="/dashboard"
					v-bind:class="{ active: currentRouteName == 'dashboard' }"
				>
					<b-icon-graph-up />&nbsp;대시보드
				</router-link>
			</li> -->
			<li class="lnb-list--item">
				<button
					type="button"
					class="btn tit"
					v-bind:class="{
						active: currentRouteName == 'user',
						fold: menuSt1,
					}"
					@click="menuSt1 = !menuSt1"
				>
					<b-icon-person-fill />&nbsp; 시스템
				</button>
				<div class="con">
					<router-link
						type="button"
						class="btn menu"
						to="/admin/item"
						v-bind:class="{ active: currentRouteName2 == 'item' }"
					>
						상관관계 & 변동성 리스트
					</router-link>
					<router-link
						type="button"
						class="btn menu"
						to="/admin/runApp"
						v-bind:class="{ active: currentRouteName2 == 'runApp' }"
					>
						페어 및 전략 설정
					</router-link>
					<router-link
						type="button"
						class="btn menu"
						to="/admin/log"
						v-bind:class="{ active: currentRouteName2 == 'log' }"
					>
						트레이딩
					</router-link>
					<router-link
						type="button"
						class="btn menu"
						to="/admin/review"
						v-bind:class="{ active: currentRouteName2 == 'review' }"
					>
						성과분석
					</router-link>
					<router-link
						type="button"
						class="btn menu"
						to="/admin/setting"
						v-bind:class="{ active: currentRouteName2 == 'setting' }"
					>
						설정
					</router-link>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'SideComponent',
	components: {},
	computed: {
		currentRouteName() {
			try {
				const pathList = this.$route.fullPath.split('/');

				return pathList[1];
			} catch {
				return false;
			}
		},
		currentRouteName2() {
			try {
				const pathList = this.$route.fullPath.split('/');

				return pathList[2];
			} catch {
				return false;
			}
		},
	},
	data() {
		return {
			menuSt1: false,
			menuSt2: false,
			menuSt3: false,
			menuSt4: false,
			menuSt5: false,
			menuSt6: false,
			menuSt7: false,
			menuSt8: false,
		};
	},
	watch: {},
	created() {},
	updated() {},
	methods: {},
};
</script>
