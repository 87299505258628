import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';
import seon from '../seon';

export default createStore({
	state: {
		token: { accessToken: '', refreshToken: '' },
		userInfo: null,
		isLoading: false,
	},
	mutations: {
		setToken(state, data) {
			state.token = data;
		},
		setUserInfo(state, data) {
			state.userInfo.level = data;
		},
		setUserInfo1(state, data) {
			state.userInfo = data;
		},
		setLoading(state, data) {
			state.isLoading = data;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		callSetToken({ state, commit }, data) {
			commit('setToken', data);
		},

		// eslint-disable-next-line no-unused-vars
		callSetUserInfo({ state, commit }, data) {
			seon.apiGET('/admin/myinfo').then(re => {
				commit('setUserInfo1', re);
				router.push({ path: '/' });
			});
		},

		// eslint-disable-next-line no-unused-vars
		callSetLoading({ state, commit }, data) {
			commit('setLoading', data);
		},
	},
	getters: {
		getToken(state) {
			if (state.token.accessToken && state.token.refreshToken) {
				return { accessToken: state.token.accessToken, refreshToken: state.token.refreshToken };
			} else {
				return false;
			}
		},

		getUserInfo(state) {
			return state.userInfo;
		},

		getLoading(state) {
			return state.isLoading;
		},
	},
	modules: {},
	plugins: [createPersistedState({ key: 'vuexStore', storage: window.sessionStorage })],
});
